<template>
    <div>
        <div class="row align-items-center mb-4">
            <div class="col-md-6">
                <h2 class="text-dark d-inline-block m-0">All Advance Requests</h2>
            </div>
            <div class="col-md-6">
                <div class="float-right" v-if="!interestLoading">
                    <button @click="interestRateUpdate = true" class="btn btn-primary btn-site mt-2">
                        Update Interest Rate - {{ rateValue }}%
                    </button>

                </div>
            </div>
        </div>


        <div>
            <SectionLoading v-if="loading" text="Advance Requests loading..." />
            <div v-else>
                <div v-if="contents && contents.length == 0" class="text-center my-5">
                    <p class="text-dark mt-5 pt-5">No Advance requests yet.</p>

                </div>
                <div v-else>
                    <div class="card siteCard">
                        <vs-tabs>

                            <vs-tab label="Reports">
                                <div class="py-2">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div>
                                                <p class="creatorTitle">Total Payments</p>
                                                <h3 class="my-2">
                                                    <span class="text-dark font-weight-bold">{{
                                                            '200000' | number("0,0")
                                                    }}</span>
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div>
                                                <p class="creatorTitle">Loan Income</p>
                                                <h3 class="my-2">
                                                    <span class="text-dark font-weight-bold">{{
                                                            '200000' | number("0,0")
                                                    }}</span>
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div>
                                                <p class="creatorTitle">Approved Credit</p>
                                                <h3 class="my-2">
                                                    <span class="text-dark font-weight-bold">{{
                                                            '200000' | number("0,0")
                                                    }}</span>
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div>
                                                <p class="creatorTitle">Remaining Credit</p>
                                                <h3 class="my-2">
                                                    <span class="text-dark font-weight-bold">{{
                                                            '200000' | number("0,0")
                                                    }}</span>
                                                </h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </vs-tab>


                            <vs-tab label="All Requests">
                                <div class="table-responsive py-2">
                                    <vs-table id="div-with-loading" max-items="10" stripe :data="contents">
                                        <template slot="thead">
                                            <vs-th class="text-dark" scope="col">S/N</vs-th>
                                            <vs-th class="text-dark" scope="col">Date requested</vs-th>
                                            <vs-th class="text-dark" scope="col">Artist</vs-th>
                                            <vs-th class="text-dark" scope="col">Amount</vs-th>
                                            <vs-th class="text-dark" scope="col">Status</vs-th>
                                            <vs-th class="text-dark" scope="col">Action</vs-th>
                                        </template>

                                        <template slot-scope="{ data }">
                                            <vs-tr :key="indextr" v-for="(request, indextr) in data">
                                                <td>
                                                    <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                                                </td>
                                                <vs-td :data="data[indextr].id">
                                                    <span class="text-dark">
                                                        {{
                                                                moment(
                                                                    new Date(data[indextr].created_at)
                                                                ).fromNow()
                                                        }}</span>
                                                </vs-td>
                                                <td>
                                                    <div class="font-weight-bold" v-if="request.user">
                                                        <router-link :to="`artists/${request.user.uuid}`">
                                                            {{ request.user.name }}
                                                        </router-link>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="font-weight-bold">
                                                        <p class="text-dark mb-0 title">
                                                            {{ request.amount | currency("₦", 0) }}
                                                        </p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <vs-chip transparent
                                                        :color="request.status == 'approved' ? 'success' : 'warning'">
                                                        {{ request.status | uppercase }}
                                                    </vs-chip>
                                                </td>

                                                <td>
                                                    <router-link :to="`/advance-requests/${request.id}`"
                                                        class="btn btn-primary btn-sm btn-site px-3">
                                                        View Request
                                                    </router-link>
                                                    <!-- <button @click="removeConfirmed(user.uuid)"
                                                        class="btn btn-outline-primary btn-sm px-3">
                                                        Remove
                                                    </button> -->
                                                </td>

                                                <!-- <td>
                                                    <p class="text-dark mb-0">
                                                        {{ user.phone }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p class="text-dark mb-0">{{ user.email }}</p>
                                                </td>
                                                <td>
                                                    <p class="text-dark mb-0">{{ user.user_type }}</p>
                                                </td>
                                                <vs-td :data="data[indextr].id">
                                                    <span class="text-dark">
                                                        {{
                                                                moment(
                                                                    new Date(data[indextr].last_seen)
                                                                ).fromNow()
                                                        }}</span>
                                                </vs-td>
                                                <td>
                                                    <router-link :to="`/user/update-password/${user.uuid}`"
                                                        class="btn btn-primary btn-sm btn-site px-3">
                                                        Change Password
                                                    </router-link>
                                                    <button @click="removeConfirmed(user.uuid)"
                                                        class="btn btn-outline-primary btn-sm px-3">
                                                        Remove
                                                    </button>
                                                </td> -->
                                            </vs-tr>
                                        </template>
                                    </vs-table>

                                    <!-- <div class="mt-4" v-if="contents && contents.data">
                                        <nav aria-label="Page navigation ">
                                            <ul class="pagination px-4" style="justify-content: right">
                                                <li class="page-item">
                                                    <a class="page-link" :disabled="true" href="javascript:;"><vs-icon
                                                            icon="chevron_left"></vs-icon></a>
                                                </li>
                                                <li :class="`page-item ${contents.current_page == count ? 'active' : ''
                                                }`" v-for="(count, index) in Math.ceil(
        contents.total / 20
    )" :key="index">
                                                    <a class="page-link" href="javascript:;">{{
                                                            count
                                                    }}</a>
                                                </li>
                                                <li class="page-item">
                                                    <a class="page-link" :disabled="true" href="javascript:;"><vs-icon
                                                            icon="chevron_right"></vs-icon></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div> -->
                                </div>
                            </vs-tab>

                        </vs-tabs>
                    </div>
                </div>
            </div>
        </div>

        <vs-popup class="rateModal" title="Update Base Interest Rate" :active.sync="interestRateUpdate">
            <form class="p-4" @submit.prevent="submitFormData" role="form">
                <div class="form-group select-input">
                    <label class="text-dark" to="interestRate">Interest Rate</label>
                    <input v-model="rateValue" required name="interestRate" id="interestRate" class="form-control" />
                </div>



                <div class="form-group text-center mt-5">
                    <button :disabled="btnLoading" type="submit" class="btn btn-primary btn-block btn-site px-5">
                        Update Interest Rate
                        <BtnLoading v-if="btnLoading" class="d-inline-block" height="18" />
                    </button>
                </div>
            </form>
        </vs-popup>


    </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../../components/SectionLoading";
import BtnLoading from "../../../components/BtnLoading";
import { throws } from "assert";

export default {
    name: "Home",
    components: {
        PlusIcon,
        SectionLoading, BtnLoading
    },
    data() {
        return {
            loading: false,
            btnLoading: false,
            interestLoading: true,
            contents: [],
            contentsDeleted: [],
            list: "tile",
            listStyles: [
                { text: "Tile View", value: "tile" },
                { text: "List View", value: "list" },
            ],
            table_options: {
                current_page: 1,
            },
            interestRateUpdate: false,
            rateValue: 0,
        };
    },
    watch: {
        "table_options.current_page": function () {
            this.getContents(true);
        },
    },
    mounted() {
        this.getBl();
        this.getInterestRate();
    },
    methods: {
        submitFormData() {
            this.btnLoading = true

            const payload = {
                rate: this.rateValue
            }

            let fetch = {
                path: `admin/loan/rate/update`,
                data: payload,
            };

            this.btnLoading = true;
            this.$store
                .dispatch("putRequest", fetch)
                .then((resp) => {
                    this.$toast.success(
                        "Interest rate updated!",
                        "Success",
                        this.$toastPosition
                    );
                    this.btnLoading = false;

                    this.interestRateUpdate = false;

                    this.getContents();
                })
                .catch((err) => {
                    this.btnLoading = false;

                    if (err.response) {
                        this.$toast.info(
                            err.response.data.message,
                            "Unable to update interest rate",
                            this.$toastPosition
                        );
                    } else {
                        this.$toast.error(
                            "An error occured while updating interest rate",
                            "Error",
                            this.$toastPosition
                        );
                    }
                });

        },

        getBl() {
            this.getContents(false);
        },

        getContents(divLoad) {
            if (divLoad) {
                this.$vs.loading({
                    container: "#div-with-loading",
                    scale: 0.6,
                });
            } else {
                this.loading = true;
            }

            // ?type=admin&page${this.table_options.current_page}

            let fetch = {
                path: `/admin/loan`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.contents = resp.data.data;

                    if (divLoad) {
                        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    this.$vs.loading.close("#div-with-loading > .con-vs-loading");
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Error",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "All Users",
                            text: "Unable to get list of users",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        },

        getInterestRate() {
            let fetch = {
                path: `/admin/loan/rate`,
            };

            this.interestLoading = true;

            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.rateValue = resp.data?.data?.rate;
                })
                .finally(() => {
                    this.interestLoading = false;
                })

        }
    },
};
</script>
  